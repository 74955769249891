
    import './styles.scoped.css';
    export default {
  "flash-with-motion": "awsui_flash-with-motion_1q84n_5lvd3_153",
  "enter": "awsui_enter_1q84n_5lvd3_153",
  "flash-body": "awsui_flash-body_1q84n_5lvd3_167",
  "flash-message": "awsui_flash-message_1q84n_5lvd3_167",
  "flash-header": "awsui_flash-header_1q84n_5lvd3_167",
  "flash-content": "awsui_flash-content_1q84n_5lvd3_168",
  "action-button-wrapper": "awsui_action-button-wrapper_1q84n_5lvd3_169",
  "dismiss-button-wrapper": "awsui_dismiss-button-wrapper_1q84n_5lvd3_170",
  "flash-icon": "awsui_flash-icon_1q84n_5lvd3_193",
  "entering": "awsui_entering_1q84n_5lvd3_206",
  "entered": "awsui_entered_1q84n_5lvd3_227",
  "exiting": "awsui_exiting_1q84n_5lvd3_332",
  "stack": "awsui_stack_1q84n_5lvd3_366",
  "animation-running": "awsui_animation-running_1q84n_5lvd3_366",
  "item": "awsui_item_1q84n_5lvd3_366",
  "flash-list-item": "awsui_flash-list-item_1q84n_5lvd3_367",
  "notification-bar": "awsui_notification-bar_1q84n_5lvd3_368",
  "collapsed": "awsui_collapsed_1q84n_5lvd3_388",
  "animation-ready": "awsui_animation-ready_1q84n_5lvd3_388",
  "expanded-only": "awsui_expanded-only_1q84n_5lvd3_388",
  "expanded": "awsui_expanded_1q84n_5lvd3_388",
  "flash": "awsui_flash_1q84n_5lvd3_153",
  "collapsible": "awsui_collapsible_1q84n_5lvd3_444",
  "short-list": "awsui_short-list_1q84n_5lvd3_450",
  "visual-refresh": "awsui_visual-refresh_1q84n_5lvd3_450",
  "status": "awsui_status_1q84n_5lvd3_597",
  "header": "awsui_header_1q84n_5lvd3_597",
  "item-count": "awsui_item-count_1q84n_5lvd3_598",
  "button": "awsui_button_1q84n_5lvd3_599",
  "type-count": "awsui_type-count_1q84n_5lvd3_632",
  "count-number": "awsui_count-number_1q84n_5lvd3_632",
  "icon": "awsui_icon_1q84n_5lvd3_666",
  "floating": "awsui_floating_1q84n_5lvd3_707",
  "flashbar": "awsui_flashbar_1q84n_5lvd3_711",
  "initial-hidden": "awsui_initial-hidden_1q84n_5lvd3_772",
  "flash-list": "awsui_flash-list_1q84n_5lvd3_367",
  "flash-focus-container": "awsui_flash-focus-container_1q84n_5lvd3_794",
  "flash-text": "awsui_flash-text_1q84n_5lvd3_824",
  "hidden": "awsui_hidden_1q84n_5lvd3_843",
  "header-replacement": "awsui_header-replacement_1q84n_5lvd3_848",
  "content-replacement": "awsui_content-replacement_1q84n_5lvd3_853",
  "dismiss-button": "awsui_dismiss-button_1q84n_5lvd3_170",
  "breakpoint-default": "awsui_breakpoint-default_1q84n_5lvd3_872",
  "action-button": "awsui_action-button_1q84n_5lvd3_169",
  "action-slot": "awsui_action-slot_1q84n_5lvd3_882",
  "flash-type-success": "awsui_flash-type-success_1q84n_5lvd3_886",
  "flash-type-error": "awsui_flash-type-error_1q84n_5lvd3_890",
  "flash-type-info": "awsui_flash-type-info_1q84n_5lvd3_894",
  "flash-type-in-progress": "awsui_flash-type-in-progress_1q84n_5lvd3_895",
  "flash-type-warning": "awsui_flash-type-warning_1q84n_5lvd3_899"
};
  