
    import './styles.scoped.css';
    export default {
  "button": "awsui_button_m5h9f_1lkia_141",
  "expanded": "awsui_expanded_m5h9f_1lkia_196",
  "offset-right-none": "awsui_offset-right-none_m5h9f_1lkia_207",
  "offset-right-l": "awsui_offset-right-l_m5h9f_1lkia_210",
  "offset-right-xxl": "awsui_offset-right-xxl_m5h9f_1lkia_213",
  "text": "awsui_text_m5h9f_1lkia_238",
  "icon": "awsui_icon_m5h9f_1lkia_242"
};
  