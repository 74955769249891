import React, { useState } from 'react'
import { useGet } from '../hooks/useGet'
import { usePut } from '../hooks/usePut'
import { useDelete } from '../hooks/useDelete'
import { RadBox } from '../common/RadBox'
import { RadButton } from '../common/RadButton'
import { RadCopyToClipboard } from '../common/RadCopyToClipboard'
import { RadHeader } from '../common/RadHeader'
import { RadModal } from '../common/RadModal'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { CollaborativeDetailContent } from '../collaborative/CollaborativeDetailContent'
import { CollaborativeEditContent } from '../collaborative/CollaborativeEditContent'
import { OrganizationDetailContent } from '../organization/OrganizationDetailContent'
import { OrganizationEditContent } from '../organization/OrganizationEditContent'
import { ProjectDetailContent } from '../project/ProjectDetailContent'
import { ProjectEditContent } from '../project/ProjectEditContent'
import { ResourceDetailContent } from '../resource/ResourceDetailContent'
import { ResourceEditContent } from '../resource/ResourceEditContent'
import { getShortText } from '../common/utilities'

export function EntityModal ({ entities, selectedEntity, setSelectedEntity, closeModal, title, description }) {
  const [formValues, setFormValues] = useState()
  const [editMode, setEditMode] = useState(false)
  const [confirmDelete, setConfirmDelete] = useState()
  const { data: entity } = useGet(
    selectedEntity != null ? `/api/${selectedEntity.type}/${selectedEntity.id}` : null,
    true
  )
  const { data: currentUser } = useGet('/api/user/current')
  const update = usePut(
    `/api/${selectedEntity?.type}/${selectedEntity?.id}`,
    formValues,
    (resp) => {
      setEditMode(false)
    }
  )
  const remove = useDelete(`/api/${selectedEntity?.type}/${selectedEntity?.id}`, () => { cleanup() })

  function cleanup () {
    setConfirmDelete(null)
    setEditMode(false)
    closeModal()
  }

  const currentUserPermissions = currentUser?.roles
    ?.reduce((acc, x) => acc.concat(x.permissions.map(y => y.name.toLowerCase())), []) ?? []
  const currentUserIsAdmin = currentUser?.roles?.some(x => x.name === 'Admin') ?? false

  const actionButtons = []
  if (currentUserIsAdmin || currentUserPermissions.includes(`${entity?.type.entity} update`)) {
    actionButtons.push(<RadButton key={actionButtons.length + 1} variant='primary' onClick={() => setEditMode(true)}>Edit</RadButton>)
  }
  if (currentUserIsAdmin || currentUserPermissions.includes(`${entity?.type} delete`)) {
    actionButtons.push(
      <RadButton variant='primary' onClick={() => setConfirmDelete(true)} disabled={entity?.isProtected}>
        Delete
      </RadButton>
    )
  }

  let footer
  if (entity != null) {
    if (confirmDelete === true) {
      footer = (
        <>
          <RadBox display='inline'>
            {`Delete ${selectedEntity?.type} permanently? This action cannot be undone.`}
          </RadBox>
          <RadBox float='right'>
            <RadSpaceBetween direction='horizontal' size='xs'>
              <RadButton variant='link' onClick={() => setConfirmDelete(null)}>Cancel</RadButton>
              <RadButton variant='primary' onClick={() => remove()}>Confirm</RadButton>
            </RadSpaceBetween>
          </RadBox>
        </>
      )
    } else if (editMode) {
      footer = (
        <>
          <RadCopyToClipboard
            copyButtonText='Copy link'
            copyErrorText='Link failed to copy'
            copySuccessText='Link copied'
            textToCopy={`${window.location.origin}/${selectedEntity?.type}/${selectedEntity?.id}`}
          />
          <RadBox float='right'>
            <RadSpaceBetween direction='horizontal' size='xs'>
              <RadButton variant='link' onClick={() => setEditMode(false)}>Cancel</RadButton>
              <RadButton variant='primary' onClick={update} disabled={entity?.isProtected}>
                Save Changes
              </RadButton>
            </RadSpaceBetween>
          </RadBox>
        </>
      )
    } else {
      footer = (
        <>
          <RadCopyToClipboard
            copyButtonText='Copy link'
            copyErrorText='Link failed to copy'
            copySuccessText='Link copied'
            textToCopy={`${window.location.origin}/${selectedEntity?.type}/${selectedEntity?.id}`}
          />
          <RadBox float='right'>
            <RadSpaceBetween direction='horizontal' size='xs'>
              {actionButtons}
            </RadSpaceBetween>
          </RadBox>
        </>
      )
    }
  }

  return (
    <RadModal
      onDismiss={() => cleanup()}
      visible={entity != null || entities?.length > 0}
      closeAriaLabel='Close modal'
      footer={footer}
      header={
        <RadHeader description={!editMode && (entity?.description || entity?.summary || description)}>
          <RadBox variant='awsui-value-large'>
            {entity?.name}{entity?.title}{entities?.[0]?.topics?.[0]?.topic?.name}{title}
          </RadBox>
        </RadHeader>
      }
      size='large'
    >
      <RadSpaceBetween size='l'>
        {entity == null && entities?.length > 0 &&
          entities.map((x) => (
            <RadBox key={x.id} padding={{ bottom: x === entities[entities.length - 1] ? 'l' : null }}>
              <RadButton
                onClick={() => setSelectedEntity({ id: x.id, type: x.entityType })}
                className='font-weight-700 font-size-16'
                variant='inline-link'
              >
                {x.name || x.title}
                {x.type != null && <RadBox display='inline'> - {x.type?.name}</RadBox>}
              </RadButton>
              {x.description != null && <RadBox color='text-status-inactive'>{getShortText(x.description)}</RadBox>}
              {x.summary != null && <RadBox color='text-status-inactive'>{getShortText(x.summary)}</RadBox>}
            </RadBox>
          ))}
        {entity != null && selectedEntity?.type === 'collaborative' && editMode === false &&
          <CollaborativeDetailContent collaborative={entity} setSelectedEntity={setSelectedEntity} />}
        {entity != null && selectedEntity?.type === 'collaborative' && editMode === true &&
          <CollaborativeEditContent
            collaborative={entity}
            formValues={formValues}
            setFormValues={setFormValues}
            modal
          />}

        {entity != null && selectedEntity?.type === 'organization' && editMode === false &&
          <OrganizationDetailContent organization={entity} setSelectedEntity={setSelectedEntity} />}
        {entity != null && selectedEntity?.type === 'organization' && editMode === true &&
          <OrganizationEditContent
            organization={entity}
            formValues={formValues}
            setFormValues={setFormValues}
            modal
          />}

        {entity != null && selectedEntity?.type === 'project' && editMode === false &&
          <ProjectDetailContent project={entity} setSelectedEntity={setSelectedEntity} />}
        {entity != null && selectedEntity?.type === 'project' && editMode === true &&
          <ProjectEditContent
            project={entity}
            formValues={formValues}
            setFormValues={setFormValues}
            modal
          />}

        {entity != null && selectedEntity?.type === 'resource' && editMode === false &&
          <ResourceDetailContent resource={entity} setSelectedEntity={setSelectedEntity} />}
        {entity != null && selectedEntity?.type === 'resource' && editMode === true &&
          <ResourceEditContent
            resource={entity}
            formValues={formValues}
            setFormValues={setFormValues}
            modal
          />}
      </RadSpaceBetween>
    </RadModal>
  )
}
