
    import './styles.scoped.css';
    export default {
  "root": "awsui_root_fvjdu_bmwbc_141",
  "button": "awsui_button_fvjdu_bmwbc_184",
  "dots": "awsui_dots_fvjdu_bmwbc_185",
  "button-disabled": "awsui_button-disabled_fvjdu_bmwbc_213",
  "arrow": "awsui_arrow_fvjdu_bmwbc_218",
  "page-number": "awsui_page-number_fvjdu_bmwbc_228",
  "button-current": "awsui_button-current_fvjdu_bmwbc_234",
  "page-item": "awsui_page-item_fvjdu_bmwbc_251",
  "root-disabled": "awsui_root-disabled_fvjdu_bmwbc_269"
};
  